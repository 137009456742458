import {Component, DestroyRef, inject, OnInit, ViewEncapsulation} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormControlNameEnum} from "./enums/form-control-name.enum";
import {KeyValue} from "@angular/common";
import {CompanyService} from "../../share/company/service/company.service";
import {mergeMap, Observable, switchMap, take} from "rxjs";
import {AccountTypeEnum} from "../../share/account/enums/account-type.enum";
import {AccountService} from "../../share/account/service/account.service";
import {UniqueEmailValidator} from "./unique-email.validator";
import {Account} from "../../share/account/models/account.model";
import {DiskSpacePropertyEnum} from "../../share/account/enums/disk-space-property.enum";
import {Router} from "@angular/router";
import {ModificationModeEnum} from "./enums/modification-mode.enum";
import {RoutesEnum} from "../../share/enums/routes.enum";
import {map, tap} from "rxjs/operators";
import { NotificationsService } from "src/app/share/notifications/service/notifications.service";
import { NotificationType } from "src/app/share/notifications/enums/notification-type.enum";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class ModifyUserComponent implements OnInit {

  destroyRef = inject(DestroyRef);

  GUL_EMAIL = '@gulndt.com';

  userForm: FormGroup = this.formBuilder.group('');

  placeholders: Record<string, string> = {};

  labels: Record<string, string> = {};

  fieldTypes: Record<string, string> = {};

  roleLabels: Record<string, string> = {};

  companies$: Observable<string[]>;

  doShowCompanies = false;

  companyFilter = '';

  modificationMode: ModificationModeEnum;

  isAccountDisabled: boolean;

  accountId: number;

  avatarFile: File;

  infoPopupVisible: boolean = false;
  actionType: string;
  popupMessage: string;

  emailValue: string;


  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private accountService: AccountService,
    private notificationService: NotificationsService,
    private router: Router
  ) {
    this.placeholders[FormControlNameEnum.ROLE] = "Choose user's role";
    this.placeholders[FormControlNameEnum.FIRST_NAME] = "Write user's given name";
    this.placeholders[FormControlNameEnum.LAST_NAME] = "Write user's family name";
    this.placeholders[FormControlNameEnum.DISPLAY_NAME] = "Write user's display name";
    this.placeholders[FormControlNameEnum.COMPANY] = "Choose your company";
    this.placeholders[FormControlNameEnum.EMAIL] = "Write user's email";
    this.placeholders[FormControlNameEnum.DISK_SPACE] = "Write amount of GB";
    this.placeholders[FormControlNameEnum.REPORT_LEFT] = "Write max. amount of reports";
    this.placeholders[FormControlNameEnum.EXPIRATION_DATE] = "Write expiration date";
    this.placeholders[FormControlNameEnum.INSTRUMENT_MODEL] = "Write instrument model";
    this.placeholders[FormControlNameEnum.SERIAL_NUMBER] = "Write serial number";
    this.placeholders[FormControlNameEnum.COMMENTS] = "No comments provided";
    this.placeholders[FormControlNameEnum.CALIBRATION_DATE] = "Expiration Date"
    this.placeholders[FormControlNameEnum.FORWARD_EMAIL] = "Write forward email address";

    this.labels[FormControlNameEnum.ROLE] = "User Account Type";
    this.labels[FormControlNameEnum.FIRST_NAME] = "Given Name";
    this.labels[FormControlNameEnum.LAST_NAME] = "Family Name";
    this.labels[FormControlNameEnum.DISPLAY_NAME] = "Display Name";
    this.labels[FormControlNameEnum.COMPANY] = "Company";
    this.labels[FormControlNameEnum.EMAIL] = "E-mail";
    this.labels[FormControlNameEnum.DISK_SPACE] = "Disk Space [GB]";
    this.labels[FormControlNameEnum.REPORT_LEFT] = "Reporting";
    this.labels[FormControlNameEnum.EXPIRATION_DATE] = "Expiration Date";
    this.labels[FormControlNameEnum.INSTRUMENT_MODEL] = "Instrument Model";
    this.labels[FormControlNameEnum.SERIAL_NUMBER] = "Serial No.";
    this.labels[FormControlNameEnum.COMMENTS] = "Comments";
    this.labels[FormControlNameEnum.AVATAR] = 'User Avatar';
    this.labels[FormControlNameEnum.CALIBRATION_DATE] = "Calibration Date";
    this.labels[FormControlNameEnum.FORWARD_EMAIL] = 'Forward email';
    this.labels[FormControlNameEnum.LOCAL_ACCOUNT] = 'Create local account?'

    this.fieldTypes[FormControlNameEnum.ROLE] = "text";
    this.fieldTypes[FormControlNameEnum.FIRST_NAME] = "text";
    this.fieldTypes[FormControlNameEnum.LAST_NAME] = "text";
    this.fieldTypes[FormControlNameEnum.DISPLAY_NAME] = "text";
    this.fieldTypes[FormControlNameEnum.COMPANY] = "text";
    this.fieldTypes[FormControlNameEnum.EMAIL] = "text";
    this.fieldTypes[FormControlNameEnum.DISK_SPACE] = "number";
    this.fieldTypes[FormControlNameEnum.REPORT_LEFT] = "number";
    this.fieldTypes[FormControlNameEnum.EXPIRATION_DATE] = "date";
    this.fieldTypes[FormControlNameEnum.INSTRUMENT_MODEL] = "text";
    this.fieldTypes[FormControlNameEnum.SERIAL_NUMBER] = "text";
    this.fieldTypes[FormControlNameEnum.COMMENTS] = "text";
    this.fieldTypes[FormControlNameEnum.AVATAR] = 'text';
    this.fieldTypes[FormControlNameEnum.CALIBRATION_DATE] = 'date';
    this.fieldTypes[FormControlNameEnum.FORWARD_EMAIL] = "text";
    this.fieldTypes[FormControlNameEnum.LOCAL_ACCOUNT] = "checkbox";

    this.roleLabels[AccountTypeEnum.ADMIN] = 'Administrator';
    this.roleLabels[AccountTypeEnum.INSTRUMENT] = 'Instrument-User';

  }

  ngOnInit() {
    if (this.router.url.toLowerCase().split('/').length === 3) {
      this.modificationMode = ModificationModeEnum.EDIT;
      this.accountId = +this.router.url.toLowerCase().split('/')[2];
      this.accountService.getAccount({ id: this.accountId }).pipe(take(1)).subscribe(account => {
        this.isAccountDisabled = account.isDisabled;
        const isAdmin = account.role === AccountTypeEnum.ADMIN;
        const isExpirationDateDisabled = account.expirationDate === null;
        this.userForm = this.formBuilder.group({
          [FormControlNameEnum.ROLE]: [{ value: account.role, disabled: true }, Validators.required],
          [FormControlNameEnum.FIRST_NAME]: [{ value: account.firstName, disabled: !isAdmin }, Validators.required],
          [FormControlNameEnum.LAST_NAME]: [{ value: account.lastName, disabled: !isAdmin }, null],
          [FormControlNameEnum.DISPLAY_NAME]: [{ value: account.displayName, disabled: false }, null],
          [FormControlNameEnum.COMPANY]: [{ value: account.company, disabled: false }, Validators.required],
          [FormControlNameEnum.EMAIL]: [{ value: account.email, disabled: true }, [Validators.required, Validators.email]],
          [FormControlNameEnum.DISK_SPACE]: [{ value: account.diskSpace.max, disabled: isAdmin || !isExpirationDateDisabled }, null],
          [FormControlNameEnum.REPORT_LEFT]: [{ value: account.maxReports, disabled: isAdmin || !isExpirationDateDisabled }, null],
          [FormControlNameEnum.EXPIRATION_DATE]: [{ value: account.expirationDate, disabled: isAdmin || isExpirationDateDisabled }, null],
          [FormControlNameEnum.INSTRUMENT_MODEL]: [{ value: account.instrumentModel, disabled: isAdmin }, null],
          [FormControlNameEnum.SERIAL_NUMBER]: [{ value: account.serialNumber, disabled: isAdmin }, null],
          [FormControlNameEnum.COMMENTS]: [{ value: account.comments, disabled: false }, Validators.maxLength(255)],
          [FormControlNameEnum.AVATAR]: [{ value: account.avatar, disabled: false }, null],
          [FormControlNameEnum.CALIBRATION_DATE]: [{value: account.calibrationDate, disabled: isAdmin}, null],
          [FormControlNameEnum.FORWARD_EMAIL]: [{value: account.forwardingEmail, disabled: isAdmin}, [Validators.email]]
        });
      });
    } else {
      this.modificationMode = ModificationModeEnum.ADD;
      this.userForm = this.formBuilder.group({
        [FormControlNameEnum.ROLE]: [null, Validators.required],
        [FormControlNameEnum.FIRST_NAME]: [{ value: '', disabled: true }, Validators.required],
        [FormControlNameEnum.LAST_NAME]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.DISPLAY_NAME]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.COMPANY]: [{ value: '', disabled: true }, Validators.required],
        [FormControlNameEnum.EMAIL]: [
          { value: '', disabled: true },
          [Validators.required, Validators.email],
          [UniqueEmailValidator.createValidator(this.accountService, FormControlNameEnum.LOCAL_ACCOUNT)]
        ],
        [FormControlNameEnum.DISK_SPACE]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.REPORT_LEFT]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.EXPIRATION_DATE]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.INSTRUMENT_MODEL]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.SERIAL_NUMBER]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.COMMENTS]: [{ value: '', disabled: true }, Validators.maxLength(255)],
        [FormControlNameEnum.AVATAR]: [{ value: '', disabled: true }, null],
        [FormControlNameEnum.FORWARD_EMAIL]: [{value: '', disabled: true}, [Validators.email]],
        [FormControlNameEnum.CALIBRATION_DATE]: [{value: '', disabled: true}],
        [FormControlNameEnum.LOCAL_ACCOUNT]: [{value: false, disabled: true}]
      });

        this.userForm.get(FormControlNameEnum.ROLE).valueChanges
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(accountType => {
            this.userForm.markAsUntouched();
            if (accountType === AccountTypeEnum.ADMIN) {
                this.setupAdministratorFormControls();
            } else if (accountType === AccountTypeEnum.INSTRUMENT) {
                this.setupInstrumentUserFormControls();
            }
        });

      this.userForm.get(FormControlNameEnum.FIRST_NAME).valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(firstName =>
          this.userForm.patchValue({
            [FormControlNameEnum.EMAIL]: firstName.replace(/\s+/g, '_') + this.GUL_EMAIL
          })
      );

      this.userForm.get(FormControlNameEnum.LOCAL_ACCOUNT).valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.userForm.get(FormControlNameEnum.EMAIL).updateValueAndValidity()
      );
    }

    this.companies$ = this.companyService.getCompanies();

    this.userForm.get(FormControlNameEnum.EMAIL)?.statusChanges.subscribe(status => {
      const emailControl = this.userForm.get(FormControlNameEnum.EMAIL);
      const checkBoxControl = this.userForm.get(FormControlNameEnum.LOCAL_ACCOUNT);
      if(emailControl.hasError('accountAlreadyExistInEntra')) {
        checkBoxControl.enable({emitEvent: false})
      } else if(checkBoxControl.value) {
        checkBoxControl.enable({ emitEvent: false})
      } else {
        checkBoxControl.disable({emitEvent: false})
      }

      if(this.emailValue !== this.userForm.get(FormControlNameEnum.EMAIL).value) {
        this.emailValue = emailControl.value;
        checkBoxControl.setValue(false, { emitEvant: false })
      }
    });


  }

  setupAdministratorFormControls() {
    this.userForm.get(FormControlNameEnum.FIRST_NAME).enable();
    this.userForm.get(FormControlNameEnum.LAST_NAME).enable();
    this.userForm.get(FormControlNameEnum.DISPLAY_NAME).enable();
    this.userForm.get(FormControlNameEnum.COMPANY).disable();
    this.userForm.get(FormControlNameEnum.EMAIL).enable();
    this.userForm.get(FormControlNameEnum.DISK_SPACE).clearValidators();
    this.userForm.get(FormControlNameEnum.DISK_SPACE).clearValidators();
    this.userForm.get(FormControlNameEnum.DISK_SPACE).disable();
    this.userForm.get(FormControlNameEnum.REPORT_LEFT).clearValidators();
    this.userForm.get(FormControlNameEnum.REPORT_LEFT).disable();
    this.userForm.get(FormControlNameEnum.EXPIRATION_DATE).clearValidators();
    this.userForm.get(FormControlNameEnum.EXPIRATION_DATE).disable();
    this.userForm.get(FormControlNameEnum.INSTRUMENT_MODEL).clearValidators();
    this.userForm.get(FormControlNameEnum.INSTRUMENT_MODEL).disable();
    this.userForm.get(FormControlNameEnum.SERIAL_NUMBER).clearValidators();
    this.userForm.get(FormControlNameEnum.SERIAL_NUMBER).disable();
    this.userForm.get(FormControlNameEnum.COMMENTS).enable();
    this.userForm.get(FormControlNameEnum.AVATAR).enable();
    this.userForm.get(FormControlNameEnum.CALIBRATION_DATE).disable();
    this.userForm.get(FormControlNameEnum.FORWARD_EMAIL).disable();
    this.userForm.get(FormControlNameEnum.LOCAL_ACCOUNT).enable();

    this.userForm.patchValue({
      [FormControlNameEnum.LAST_NAME]: '',
      [FormControlNameEnum.COMPANY]: 'Guided Ultrasonics',
      [FormControlNameEnum.DISK_SPACE]: null,
      [FormControlNameEnum.REPORT_LEFT]: null,
      [FormControlNameEnum.EXPIRATION_DATE]: null,
      [FormControlNameEnum.INSTRUMENT_MODEL]: 'N/A',
      [FormControlNameEnum.SERIAL_NUMBER]: 'N/A'
    });
  }

  setupInstrumentUserFormControls() {
    this.userForm.get(FormControlNameEnum.FIRST_NAME).disable();
    this.userForm.get(FormControlNameEnum.LAST_NAME).disable();
    this.userForm.get(FormControlNameEnum.DISPLAY_NAME).enable();
    this.userForm.get(FormControlNameEnum.COMPANY).enable();
    this.userForm.get(FormControlNameEnum.EMAIL).enable();
    this.userForm.get(FormControlNameEnum.DISK_SPACE).enable();
    this.userForm.get(FormControlNameEnum.DISK_SPACE).addValidators(Validators.pattern('^[0-9]*$'));
    this.userForm.get(FormControlNameEnum.REPORT_LEFT).enable();
    this.userForm.get(FormControlNameEnum.REPORT_LEFT).addValidators(Validators.pattern('^[0-9]*$'));
    this.userForm.get(FormControlNameEnum.EXPIRATION_DATE).enable();
    this.userForm.get(FormControlNameEnum.INSTRUMENT_MODEL).enable();
    this.userForm.get(FormControlNameEnum.INSTRUMENT_MODEL).addValidators(Validators.required);
    this.userForm.get(FormControlNameEnum.SERIAL_NUMBER).enable();
    this.userForm.get(FormControlNameEnum.SERIAL_NUMBER).addValidators(Validators.required);
    this.userForm.get(FormControlNameEnum.COMMENTS).enable();
    this.userForm.get(FormControlNameEnum.AVATAR).enable();
    this.userForm.get(FormControlNameEnum.CALIBRATION_DATE).disable();
    this.userForm.get(FormControlNameEnum.FORWARD_EMAIL).enable();
    this.userForm.get(FormControlNameEnum.LOCAL_ACCOUNT).enable();

    this.userForm.addValidators(this.reportingValidator);

    this.userForm.patchValue({
      [FormControlNameEnum.FIRST_NAME]: 'N/A',
      [FormControlNameEnum.LAST_NAME]: 'N/A',
      [FormControlNameEnum.COMPANY]: '',
      [FormControlNameEnum.DISK_SPACE]: 10,
      [FormControlNameEnum.REPORT_LEFT]: 100,
      [FormControlNameEnum.EXPIRATION_DATE]: null,
      [FormControlNameEnum.INSTRUMENT_MODEL]: '',
      [FormControlNameEnum.SERIAL_NUMBER]: ''
    });
  }

  onConfirm() {
      this.userForm.markAllAsTouched();
      if (this.userForm.valid) {
          switch (this.modificationMode) {
              case ModificationModeEnum.ADD: {
                  this.companies$.pipe(
                      map(companies => companies.map(company => company.toLowerCase()).includes(this.userForm.get(FormControlNameEnum.COMPANY).value)),
                      mergeMap(companyExists => companyExists
                          ? this.accountService.createAccount(this.mapFormToAccount(), this.avatarFile)
                          : this.companyService.addCompany(this.userForm.get(FormControlNameEnum.COMPANY).value).pipe(
                              switchMap(() => this.accountService.createAccount(this.mapFormToAccount(), this.avatarFile))
                          )),
                          tap(() => this.notificationService.notify({ message: 'User Added!', type: NotificationType.INFO })),
                          takeUntilDestroyed(this.destroyRef)
                  ).subscribe(() => void this.router.navigateByUrl(RoutesEnum.EMPTY));
                  break;
              }
              case ModificationModeEnum.EDIT: {
                  const dirtyValues = {};
                  Object.keys(this.userForm.controls).forEach(c => {
                      const currentControl = this.userForm.get(c);
                      if (currentControl.dirty) {
                          dirtyValues[c] = currentControl.value;
                      }
                  });
                this.companies$.pipe(
                      map(companies => companies.map(company => company.toLowerCase()).includes(this.userForm.get(FormControlNameEnum.COMPANY).value)),
                      mergeMap(companyExists => companyExists
                          ? this.accountService.editAccount({...dirtyValues, company: this.userForm.get(FormControlNameEnum.COMPANY).value } as Account, this.accountId, this.avatarFile)
                          : this.companyService.addCompany(this.userForm.get(FormControlNameEnum.COMPANY).value).pipe(
                              switchMap(() => this.accountService.editAccount({...dirtyValues, company: this.userForm.get(FormControlNameEnum.COMPANY).value} as Account, this.accountId, this.avatarFile))
                          )),
                          tap(() => this.notificationService.notify({ message: 'User Edited!', type: NotificationType.INFO })),
                          takeUntilDestroyed(this.destroyRef)
                  ).subscribe(() => void this.router.navigateByUrl(RoutesEnum.EMPTY));``
                  break;
              }
          }
      }
  }

  onCancel() {
    void this.router.navigateByUrl(RoutesEnum.EMPTY);
  }

  onEnableDisable() {
      this.accountService.editAccount({ isDisabled: !this.isAccountDisabled, company: this.userForm.get(FormControlNameEnum.COMPANY).value } as Account, this.accountId, this.avatarFile).pipe(take(1)).subscribe(result => {
        void this.router.navigateByUrl(RoutesEnum.EMPTY)
        }
      );
  }

  openPopup(actionType: string){
    this.actionType = actionType;
    switch (this.actionType) {
      case 'CONFIRM':
        this.popupMessage = `Are you sure you want to apply changes?`;
        break;
      case 'DELETE':
        this.popupMessage = `Are you sure you want to delete account?`;
        break;
      case 'CANCEL':
        this.popupMessage = `Are you sure you want to quit? \n Changes would not be saved...`;
        break;
      case 'ENABLE_DISABLE':
        this.popupMessage = `Are you sure you want to proceed?`;
        break;
      case 'CALIBRATE':
        this.popupMessage = `Are you sure you want to change calibration date for that account?`;
        break;
    }
    if(this.actionType && this.actionType !== '') this.infoPopupVisible = true;
  }

  popupConfirm() {
    switch (this.actionType) {
      case 'CONFIRM':
        this.onConfirm();
        break;
      case 'DELETE':
        this.onDelete();
        break;
      case 'CANCEL':
        this.onCancel();
        break;
      case 'ENABLE_DISABLE':
        this.onEnableDisable();
        break;
      case 'CALIBRATE':
        this.onCalibrate();
        break;
    }
  }

  popupClose() {
    this.infoPopupVisible = false;
  }

  onDelete() {
      this.accountService.deleteAccount(this.accountId).pipe(
          take(1),
          tap(() => this.notificationService.notify({ message: 'User Deleted!', type: NotificationType.INFO }))
         ).subscribe(() => void this.router.navigateByUrl(RoutesEnum.EMPTY));
  }
  private onCalibrate() {
      this.accountService.changeCalibrationDate(this.accountId)
          .subscribe(res => {
            if(res) void this.router.navigate(['/']);
          });
  }

  reportingValidator(formGroup: FormGroup) {
    if (!formGroup || formGroup.get(FormControlNameEnum.ROLE).value === AccountTypeEnum.ADMIN) { return null; }

    if (!formGroup.get(FormControlNameEnum.EXPIRATION_DATE).value &&
      (!formGroup.get(FormControlNameEnum.REPORT_LEFT).value || !formGroup.get(FormControlNameEnum.DISK_SPACE).value))
    {
      formGroup.get(FormControlNameEnum.EXPIRATION_DATE).setErrors({ required: true });
      if (!formGroup.get(FormControlNameEnum.REPORT_LEFT).value) {
        formGroup.get(FormControlNameEnum.REPORT_LEFT).setErrors({ required: true });
      }
      if (!formGroup.get(FormControlNameEnum.DISK_SPACE).value) {
        formGroup.get(FormControlNameEnum.DISK_SPACE).setErrors({ required: true });
      }
      return Validators.required(formGroup.get(FormControlNameEnum.EXPIRATION_DATE));
    }
    if (formGroup.get(FormControlNameEnum.EXPIRATION_DATE).value &&
      (formGroup.get(FormControlNameEnum.REPORT_LEFT).value || formGroup.get(FormControlNameEnum.DISK_SPACE).value))
    {
      formGroup.get(FormControlNameEnum.EXPIRATION_DATE).setErrors({ noExpirationDate: true });
      formGroup.get(FormControlNameEnum.EXPIRATION_DATE).markAsTouched();
      return Validators.required(formGroup.get(FormControlNameEnum.EXPIRATION_DATE));
    }

    formGroup.get(FormControlNameEnum.EXPIRATION_DATE).setErrors(null);
    formGroup.get(FormControlNameEnum.REPORT_LEFT).setErrors(null);
    formGroup.get(FormControlNameEnum.DISK_SPACE).setErrors(null);
    return null;
  }

  selectCompany(company: string){
    this.userForm.patchValue({
      [FormControlNameEnum.COMPANY]: company
    });
    this.companyFilter = company;
  }

  mapFormToAccount(): Account {
    return {
      ...this.userForm.value,
      [FormControlNameEnum.DISK_SPACE]: {
        [DiskSpacePropertyEnum.MAX]: this.userForm.value[FormControlNameEnum.DISK_SPACE],
        [DiskSpacePropertyEnum.USED]: 0
      },
      company: this.userForm.get(FormControlNameEnum.COMPANY).value
    }
  }

  originalOrder = (_: KeyValue<string,string>, __: KeyValue<string,string>): number => {
    return 0;
  }

  protected readonly FormControlNameEnum = FormControlNameEnum;
  protected readonly AccountTypeEnum = AccountTypeEnum;
  protected readonly ModificationModeEnum = ModificationModeEnum;

  updateAvatar(file: File) {
    this.userForm.get(FormControlNameEnum.AVATAR).patchValue(file ? URL.createObjectURL(file) : '');
    this.avatarFile = file ?? undefined;
  }

  protected readonly blur = blur;


}
