<div *ngIf="userForm.get(FormControlNameEnum.ROLE)" class="modify-user-container">
  <div class="full-form-container">
    <div>
      <!--User Settings title on the top left-->
      <div class="title-container">
        <div class="rectangle"></div>
        <h2 class="gul-font-color thick-font">User Settings</h2>
      </div>

      <div class="form-with-avatar">
        <form [formGroup]="userForm" class="userForm">
          <!--user form-->
          <div class="userFormControls">
            <!--classic inputs-->
            <div class="form-control" *ngFor="let controlName of FormControlNameEnum | keyvalue: originalOrder">
              <app-form-field *ngIf="controlName.value !== FormControlNameEnum.ROLE &&
              controlName.value !== FormControlNameEnum.COMPANY &&
              controlName.value !== FormControlNameEnum.AVATAR &&
              controlName.value !== FormControlNameEnum.LOCAL_ACCOUNT &&
              (controlName.value !== FormControlNameEnum.CALIBRATION_DATE || modificationMode === ModificationModeEnum.EDIT)"
                              [label]="labels[controlName.value]"
                              >
                <input *ngIf="userForm.get(FormControlNameEnum.ROLE).value !== AccountTypeEnum.ADMIN || (
                userForm.get(FormControlNameEnum.ROLE).value === AccountTypeEnum.ADMIN &&
                controlName.value !== FormControlNameEnum.DISK_SPACE &&
                controlName.value !== FormControlNameEnum.REPORT_LEFT &&
                controlName.value !== FormControlNameEnum.EXPIRATION_DATE); else unlimitedLabel"
                       [type]="fieldTypes[controlName.value]" class="form-input" input
                       [formControlName]="controlName.value"
                       [placeholder]="placeholders[controlName.value]"
                       [class.invalid]="userForm.controls[controlName.value].invalid && userForm.controls[controlName.value].touched"/>
                <!--Unlimited labels-->
                <ng-template #unlimitedLabel>
                  <span class="non-editable-input form-input display-flex small-padding-top">Unlimited</span>
                </ng-template>
              </app-form-field>
              <small class="error" *ngIf="controlName.value === FormControlNameEnum.EMAIL && userForm.get(FormControlNameEnum.EMAIL).hasError('emailAlreadyExists')">Email already exists</small>
              <small class="error" *ngIf="controlName.value === FormControlNameEnum.EMAIL && userForm.get(FormControlNameEnum.EMAIL).hasError('accountAlreadyExistInEntra')">Account with this Email already exists in MS Entra, use "Create Local Account" option</small>
              <small class="error" *ngIf="userForm.controls[controlName.value]?.touched && userForm.get(controlName.value).hasError('required')">This field is required</small>
              <small class="error" *ngIf="userForm.controls[controlName.value]?.touched && userForm.get(controlName.value).hasError('noExpirationDate')">In order to add Expiration Date please remove <b>Reporting</b> and <b>Disk Space</b> limits</small>

              <div *ngIf="modificationMode === ModificationModeEnum.ADD && controlName.value === FormControlNameEnum.LOCAL_ACCOUNT" class="role-container">
                <h4 class="local-account gul-font-color">{{labels[controlName.value]}}</h4>
                <mat-checkbox [formControlName]="controlName.value" [checked]="userForm.get(controlName.value).value" color="primary">
                </mat-checkbox>
              </div>
              <mat-form-field *ngIf="controlName.value === FormControlNameEnum.ROLE" class="role-container">
                <label>{{labels[controlName.value]}}</label>
                <mat-select [placeholder]="placeholders[controlName.value]" [formControlName]="controlName.value">
                  <mat-option *ngFor="let accountType of AccountTypeEnum | keyvalue" [value]="accountType.value">
                    {{roleLabels[accountType.value]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!--company selection-->
              <div *ngIf="controlName.value === FormControlNameEnum.COMPANY" class="company-container">
                <app-form-field  [label]="labels[controlName.value]">
                  <input [type]="fieldTypes[controlName.value]" class="form-input" input
                         [formControlName]="controlName.value"
                         [placeholder]="placeholders[controlName.value]"
                         [class.invalid]="userForm.controls[controlName.value].invalid && userForm.controls[controlName.value].touched"
                         (keyup)="companyFilter = $event.target['value']"
                         (focusin)="doShowCompanies = true;"
                         (focusout)="doShowCompanies = false;"
                  />
                </app-form-field>
                <ng-container *ngIf="(companies$ | async | filterStringList: companyFilter) as filteredCompanies">
                  <div class="company-list" [ngClass]="{'company-list-border': filteredCompanies.length > 0}" *ngIf="doShowCompanies">
                    <li class="company-string" *ngFor="let company of filteredCompanies" (mousedown)="selectCompany(company)">
                      {{company}}
                    </li>
                  </div>
                </ng-container>
              </div>

            </div>
<!--            <div></div>-->
          </div>
        </form>

        <!--avatar on the right-->
        <div class="image-upload">
          <app-image-upload (imgChange)="updateAvatar($event)" [accountId]="accountId" [url]="userForm.controls[FormControlNameEnum.AVATAR].value">
          </app-image-upload>
        </div>
      </div>
    </div>

    <!--buttons on the bottom-->
    <div class="buttons-container">
<!--      <button class="classic-button button-margins" (click)="onConfirm()">CONFIRM</button>-->
      <button class="classic-button button-margins" (click)="openPopup('CONFIRM')">CONFIRM</button>
      <button class="classic-button button-margins" (click)="openPopup('CANCEL')">CANCEL</button>
      <button class="classic-button button-margins" *ngIf="modificationMode === ModificationModeEnum.EDIT" (click)="openPopup('ENABLE_DISABLE')">
        {{ isAccountDisabled ? 'ENABLE' : 'DISABLE' }}
      </button>
      <button class="classic-button button-margins red-button" *ngIf="modificationMode === ModificationModeEnum.EDIT" (click)="openPopup('DELETE')">DELETE</button>
      <button class="classic-button button-margins"
              *ngIf="modificationMode === ModificationModeEnum.EDIT &&
               (userForm.get(FormControlNameEnum.ROLE).value === AccountTypeEnum.INSTRUMENT)"
              (click)="openPopup('CALIBRATE')">CALIBRATE</button>
    </div>
    <div class="popup-background" *ngIf="infoPopupVisible">
      <div class="popup-content">
        <p class="popup-message"> {{ popupMessage }} </p>
        <div class="popup-buttons">
          <button class="popup-button confirm" (click)="popupConfirm()">YES</button>
          <button class="popup-button close" (click)="popupClose()">NO</button>
        </div>
      </div>
    </div>
  </div>
</div>
